import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { intervalToDuration } from 'date-fns';
import ReactCountryFlag from 'react-country-flag';
import countries from 'i18n-iso-countries';
import { UserIcon } from '@heroicons/react/24/outline';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://pocketbase.fullbread.ru/');
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

const humanizeDuration = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const parts = [];
  if (duration.days) parts.push(`${duration.days} день`);
  if (duration.hours) parts.push(`${duration.hours} час`);
  if (duration.minutes) parts.push(`${duration.minutes} мин.`);
  if (duration.seconds) parts.push(`${duration.seconds} сек.`);

  return parts.join(', ');
};

const getSteamData = async (steamID) => {
  try {
    const response = await axios.post(
      'https://steamdata.fullbread.ru/steam-users',
      { steamIds: [steamID.split(':')[1]] }
    );

    const playerData = response.data[0];
    return playerData;
  } catch (error) {
    console.error('Error fetching Steam data:', error);
    return null;
  }
};

export default function Example() {
  const [endpoint, setEndpoint] = useState('public');
  const [playersData, setPlayersData] = useState([]);
  const [queryPlayersData, setQueryPlayersData] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [loading, setLoading] = useState(false);
  const [top_donations, setTopDontaions] = useState([]);

  const getTopDonations = async () => {
    try {
      const response = await pb.collection('donations_top_30').getList(1, 100, {
      });

      setTopDontaions(response.items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchData = useCallback(() => {
    setLoading(true);
    setPlayersData([]);
    setQueryPlayersData([]);
    setTimeout(() => {
      getTopDonations();

    }, 20);

    axios
      .get(`https://request.fullbread.ru/rcon/players/${endpoint}`)
      .then((response) => {
        const players = response.data.players.filter((player) => player.CountryCode);
        setPlayersData(players);

        players.forEach(async (player) => {
          const steamID = player.NetID;
          if (steamID.startsWith('SteamNWI:')) {
            const steamData = await getSteamData(steamID);
            if (steamData) {
              setAvatars((prevAvatars) => ({
                ...prevAvatars,
                [steamID]: {
                  avatarUrl: steamData.avatarmedium,
                  profileUrl: steamData.profileurl,
                },
              }));
            }
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching players data:', error);
      })
      .finally(() => setLoading(false));

    axios
      .get(`https://request.fullbread.ru/players/${endpoint}`)
      .then((response) => {
        setQueryPlayersData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching query players data:', error);
      })
      .finally(() => setLoading(false));
  }, [endpoint]);

  useEffect(() => {
    fetchData(); // Automatically fetch data on initial load
  }, [fetchData]);

  useEffect(() => {
    if (playersData.length && queryPlayersData.length) {
      const updatedPlayersData = playersData.map((player) => {
        const matchingPlayer = queryPlayersData.find((qp) => qp.name === player.Name);
        if (matchingPlayer) {
          player.Duration = matchingPlayer.duration;
        }
        return player;
      });
      setPlayersData(updatedPlayersData);
    }
  }, [playersData, queryPlayersData]);

  const handleEndpointChange = (newEndpoint) => {
    if (loading) return;
    setEndpoint(newEndpoint);
  };

  return (
    <div className="bg-darker-light min-h-screen px-5 pt-8">
      <div className="pb-10 container mx-auto">
        <div className="flex gap-4 mb-6">
          <button
            className={`px-4 py-2 border rounded-lg p-2 ${endpoint === 'public' ? 'bg-darker-light text-white' : 'bg-gray-200'
              } ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleEndpointChange('public')}
            disabled={loading}
          >
            {loading && endpoint === 'public' ? 'Loading...' : 'classic'}
          </button>
          <button
            className={`px-4 py-2 border rounded-lg p-2 ${endpoint === 'hardcore' ? 'bg-darker-light text-white' : 'bg-gray-200'
              } ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleEndpointChange('hardcore')}
            disabled={loading}
          >
            {loading && endpoint === 'hardcore' ? 'Loading...' : 'hardcore'}
          </button>
          <button
            className={`px-4 py-2 border rounded-lg p-2 ${endpoint === 'bomj' ? 'bg-darker-light text-white' : 'bg-gray-200'
              } ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleEndpointChange('bomj')}
            disabled={loading}
          >
            {loading && endpoint === 'bomj' ? 'Loading...' : 'classic #2'}
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="mt-6 w-full whitespace-nowrap text-left">
            <colgroup>
              <col className="w-full sm:w-4/12" />
              <col className="lg:w-4/12" />
              <col className="lg:w-2/12" />
              <col className="lg:w-1/12" />
            </colgroup>
            <thead className="border-b border-white/10 text-sm leading-6 text-white">
              <tr>
                <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                  Игрок
                </th>
                <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Счет
                </th>
                <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Страна
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white/5">
              {playersData.map((player) => {
                const isTopDonor = top_donations.some(donor => donor.platform.includes(player.NetID));

                return (
                  <tr key={player.ID}>
                    <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                      <div className="flex items-center gap-x-4">
                        {avatars[player.NetID]?.avatarUrl ? (
                          <img
                            src={avatars[player.NetID].avatarUrl}
                            alt={`${player.Name} avatar`}
                            className="h-8 w-8 rounded-full bg-gray-800"
                          />
                        ) : (
                          <UserIcon className="w-5 h-5 ml-1 justify-center text-white" aria-hidden="true" />
                        )}
                        <a
                          href={avatars[player.NetID]?.profileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`truncate text-sm font-medium leading-6 text-white ${isTopDonor ? 'glowing-text' : ''}`}
                        >
                          {player.Name}
                        </a>
                      </div>
                    </td>
                    <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-20">
                      <div className="flex gap-x-3">
                        <div className="font-mono text-sm leading-6 text-gray-400">{player.Score}</div>
                      </div>
                    </td>
                    <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8 lg:pr-20">
                      <ReactCountryFlag
                        countryCode={player.CountryCode}
                        title={countries.getName(player.CountryCode, 'ru')}
                        svg
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
